import * as React from 'react'

import { SafeHtml } from '@thg-commerce/gravity-elements'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'
import { spacing, Text } from '@thg-commerce/gravity-theme'

import { styled } from '../../theme'

export interface StripBannerProps {
  stripBannerURL: string | undefined
  stripBannerText: string | undefined
}

const StyledSafeHtml = styled(SafeHtml)`
  ${(props) =>
    Text(
      props.theme.widgets.stripBanner.font.entry,
      props.theme.widgets.stripBanner.font.style,
    )};
  color: ${(props) => props.theme.widgets.stripBanner.font.textColor};
  text-transform: ${(props) => props.theme.widgets.stripBanner.font.transform};
  text-decoration: ${(props) =>
    props.theme.widgets.stripBanner.font.textDecoration};
  * {
    ${(props) =>
      Text(
        props.theme.widgets.stripBanner.font.entry,
        props.theme.widgets.stripBanner.font.style,
      )};
    color: ${(props) => props.theme.widgets.stripBanner.font.textColor};
    text-transform: ${(props) =>
      props.theme.widgets.stripBanner.font.transform};
    text-decoration: ${(props) =>
      props.theme.widgets.stripBanner.font.textDecoration};
  }
`

const PromotionLink = styled.a`
  display: block;
  margin-top: ${(props) => props.theme.widgets.stripBanner.margin.top};
  margin-bottom: ${(props) => props.theme.widgets.stripBanner.margin.bottom};
  background-color: ${(props) =>
    props.theme.widgets.stripBanner.backgroundColor};
  box-shadow: ${(props) => props.theme.widgets.stripBanner.boxShadow};
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  padding: ${spacing(1)};
  text-decoration: none;
  border: 3px solid transparent;
  outline: none;
  color: ${(props) => props.theme.widgets.stripBanner.font.textColor};
  ${(props) =>
    props.theme.widgets.stripBanner.backgroundColor === '#ffffff'
      ? `border-bottom: 1px solid #d2d2d2;`
      : ''}

  &:hover {
    background-color: ${(props) =>
      props.theme.widgets.stripBanner.hoverBackgroundColor};
    color: ${(props) => props.theme.widgets.stripBanner.hoverTextColor};
    text-decoration: ${(props) =>
      props.theme.widgets.stripBanner.hoverTextDecoration};

    ${StyledSafeHtml}, ${StyledSafeHtml} * {
      color: ${(props) => props.theme.widgets.stripBanner.hoverTextColor};
    }
  }

  &:focus {
    border: ${(props) =>
      `${props.theme.widgets.stripBanner.border.focusWidth} 
      solid 
      ${
        props.theme.widgets.stripBanner.border.focusColor ||
        props.theme.colors.palette.brand.base
      }`};
    background-color: ${(props) =>
      props.theme.widgets.stripBanner.focusBackgroundColor};

    ${StyledSafeHtml}, ${StyledSafeHtml} * {
      color: ${(props) => props.theme.widgets.stripBanner.focusTextColor};
    }
  }
`

const PrefetchPromotionLink = withPrefetch('href', PromotionLink)

export const StripBanner = (props: StripBannerProps) => {
  if (!props.stripBannerText) {
    return null
  }

  return (
    <PrefetchPromotionLink
      data-testid="strip-banner"
      href={props.stripBannerURL}
    >
      <StyledSafeHtml content={props.stripBannerText} />
    </PrefetchPromotionLink>
  )
}
